
import DropzoneBase from "@/components/base/input/dropzone/DropzoneBase"
import media from "@/mixins/media"
import { MEDIA_TYPES } from "@/enums/fileTypes"
export default {
    components: {
        DropzoneBase,
    },
    mixins: [media],
    emits: ["filesDropped", "image", "video"],
    props: {
        maxImageSize: {
            type: Number,
            default: 15e6, // 15MB
        },
        maxVideoSize: {
            type: Number,
            default: 500e6, // 500MB
        },
        /** Array of accepted types */
        accept: {
            type: Array,
            default: () => MEDIA_TYPES,
        },
        /** Shows warning for multiple files, only evaluates first image */
        single: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            error: "",
            showError: false,
        }
    },
    methods: {
        showErrorSnackbar(message) {
            this.showError = false
            this.$nextTick(() => {
                this.error = message
                this.showError = true
            })
        },
        async receiveMedia(files) {
            if (!files || files[0] === undefined) return /* no file selected */
            this.$emit("filesDropped", files)
            if (this.single && files.length > 1) {
                this.showErrorSnackbar(
                    "Achtung: Bitte nur eine Datei auswählen"
                )
                files = [files[0]]
            }
            for (const file of files) {
                if (this.isImage(file)) {
                    if (this.validateImage(file)) {
                        this.$emit("image", file)
                    }
                } else if (this.isVideo(file)) {
                    if (this.validateVideo(file)) {
                        this.$emit("video", file)
                    }
                } else {
                    this.showErrorSnackbar(
                        `Unbekannter Dateityp: ${file.name}${file.type}`
                    )
                }
                await new Promise((resolve) => setTimeout(resolve, 500))
            }
        },
        validateImage(image) {
            if (this.accept.indexOf(image.type) === -1) {
                this.showErrorSnackbar(
                    `Ungültiger Bild-Dateityp: ${image.name}${image.type}`
                )
                return false
            }
            if (image.size > this.maxImageSize) {
                this.showErrorSnackbar(`Bild zu groß: ${image.name}`)
                return false
            }
            return true
        },
        validateVideo(video) {
            if (this.accept.indexOf(video.type) === -1) {
                this.showErrorSnackbar(
                    `Ungültiger Video-Dateityp: ${video.name}${video.type}`
                )
                return false
            }
            if (video.size > this.maxVideoSize) {
                this.showErrorSnackbar(`Video zu groß: ${video.name}`)
                return false
            }
            return true
        },
    },
}
