import { getMediaLibrary } from "@/services/v2/media.service"
import Vue from "vue"

const galleries = []

export const TYPE_VIDEO = "video"
export const TYPE_IMAGE = "image"
export const TYPE_ALL = "all"

/** all globally available media */
export const media = Vue.observable({
    images: [],
    videos: [],
    initialized: false,
})

/** Initializes with all images and videos of a media library.
 * Will override existing media if called multiple times.
 *
 * @param {Ref} api - reference to this.$api (api plugin)
 * @param {Number, String} mediaLibraryId - id of media library
 */
export function init(api, mediaLibraryId) {
    api.run(getMediaLibrary, mediaLibraryId).then((data) => {
        Object.assign(media, data)
        galleries.forEach((gallery) => {
            gallery._fill()
        })
    })
    media.initialized = true
}

/** Adds image to global media and all galleries */
export function addImage(image) {
    if (!image) return
    if (media.images.includes(image)) return
    media.images.unshift(image)
    galleries.forEach((gallery) => {
        if (gallery.media.includes(image)) return
        if ([TYPE_IMAGE, TYPE_ALL].includes(gallery.type)) {
            gallery.media.push(image)
        }
    })
}

/** Adds video to global media and all galleries */
export function addVideo(video) {
    if (!video) return
    if (media.videos.includes(video)) return
    media.videos.unshift(video)
    galleries.forEach((gallery) => {
        if (gallery.media.includes(video)) return
        if ([TYPE_VIDEO, TYPE_ALL].includes(gallery.type)) {
            gallery.media.push(video)
        }
    })
}

export function filterDuplicates(list) {
    if (!list) return
    const hashes = new Set()
    return list.filter((item) => {
        if (hashes.has(item.content_hash)) return false
        hashes.add(item.content_hash)
        return true
    })
}

/** Creates a new Gallery that can be used to display media in a gallery.
 * It scoped order of media to the gallery and can be used to add/remove media (locally and globally).
 *
 * Destroy the gallery by calling gallery.destroy() to remove it from the global list of galleries.
 *
 * @param {String} type - "all", "image", "video" - Use TYPE_* constants
 */
export function newGallery(type = TYPE_ALL, allowDuplicates = false) {
    const gallery = Vue.observable({
        type,
        media: [],
        /**
         * @param {Image Object} image
         * @param {Object} options
         * moveToFront: if true, move image to front of gallery
         * local: if true, only adds image to local gallery
         */
        addImage(image, { moveToFront = false, local = false } = {}) {
            if (!image) return
            if (![TYPE_IMAGE, TYPE_ALL].includes(type)) return
            this._addInOrder(image, moveToFront)
            if (local) return
            addImage(image)
        },
        /**
         * @param {Video Object} video
         * @param {Object} options
         * moveToFront: if true, move video to front of gallery
         * local: if true, only adds video to local gallery
         */
        addVideo(video, { moveToFront = false, local = false } = {}) {
            if (!video) return
            if (![TYPE_VIDEO, TYPE_ALL].includes(type)) return
            this._addInOrder(video, moveToFront)
            if (local) return
            addVideo(video)
        },
        /**
         * @param {Image Object} image
         * @param {Object} options
         * local: if true, only remove from local gallery
         */
        removeImage(image, { local = false } = {}) {
            if (!image) return
            const index = this.media.indexOf(image)
            if (index > -1) this.media.splice(index, 1)
            if (local) return
            const globalIndex = media.images.indexOf(image)
            if (globalIndex > -1) media.images.splice(globalIndex, 1)
        },
        /**
         * @param {Video Object} video
         * @param {Object} options
         * local: if true, only remove from local gallery
         */
        removeVideo(video, { local = false } = {}) {
            if (!video) return
            const index = this.media.indexOf(video)
            if (index > -1) this.media.splice(index, 1)
            if (local) return
            const globalIndex = media.videos.indexOf(video)
            if (globalIndex > -1) media.videos.splice(globalIndex, 1)
        },
        /** for internal use only */
        _addInOrder(media, moveToFront = false) {
            if (moveToFront) {
                const index = this.media.indexOf(media)
                if (index > -1) this.media.splice(index, 1)
                this.media.unshift(media)
            }
        },
        _fill() {
            if (allowDuplicates) {
                this.media = getMedia(type)
            } else {
                this.media = filterDuplicates(getMedia(type))
            }
        },
        initialized() {
            return media.initialized
        },
        destroy() {
            const index = galleries.indexOf(gallery)
            if (index > -1) galleries.splice(index, 1)
        },
    })
    gallery._fill()
    galleries.push(gallery)
    return gallery
}

function getMedia(type) {
    if (type === TYPE_ALL) return mergeAlternating(media.images, media.videos)
    if (type === TYPE_IMAGE) return [...media.images]
    if (type === TYPE_VIDEO) return [...media.videos]
}

function mergeAlternating(array1, array2) {
    const result = []
    const l = Math.min(array1.length, array2.length)
    for (let i = 0; i < l; i++) {
        result.push(array1[i], array2[i])
    }
    result.push(...array1.slice(l), ...array2.slice(l))
    return result
}
