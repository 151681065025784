
import UploadPreview from "@/components/base/image/UploadPreview"
import UploadVideoPreview from "@/components/base/video/UploadVideoPreview"
import FileInput from "@/components/base/input/FileInput"
import SelectSwitch from "@/components/base/input/SelectSwitch"

import {
    newGallery,
    TYPE_ALL,
    TYPE_IMAGE,
    TYPE_VIDEO,
} from "@/helpers/mediaGallery"

import videoService from "@/services/video.service"
import imageService from "@/services/image.service"
import draggable from "vuedraggable"
import media from "@/mixins/media"
import { mdiChevronLeft } from "@mdi/js"

import { MEDIA_TYPES } from "@/enums/fileTypes"
import { mdiPlus, mdiDelete, mdiCrop } from "@mdi/js"
import MediaDropzone from "@/components/base/input/dropzone/MediaDropzone"

function chunkArray(originalArray, itemsPerSubArray) {
    let result = []

    for (let i = 0; i < originalArray.length; i += itemsPerSubArray) {
        let chunk = originalArray.slice(i, i + itemsPerSubArray)

        result.push({
            index: i / itemsPerSubArray,
            items: chunk,
        })
    }

    return result
}

const mediaTypes = {
    all: "Alles",
    image: "Bilder",
    video: "Videos",
}

export default {
    name: "MediaUploadList",
    components: {
        draggable,
        UploadPreview,
        FileInput,
        UploadVideoPreview,
        MediaDropzone,
        SelectSwitch,
        Cropper: () => import("@/components/base/cropper/Cropper"),
        Button: () => import("@/components/base/Button"),
        Modal: () => import("@/components/base/modal/Modal"),
        MediaGallery: () =>
            import("@/components/job/editor/static/MediaGallery"),
    },
    mixins: [media],
    emits: ["uploading", "update", "dragging", "update:title"],
    props: {
        mediaList: {
            type: Array,
            default: () => [],
        },
        mediaPerRow: {
            type: Number,
            default: 4,
        },
        /**
         * this requires the gallery instance to be initialized somewhere else
         * DO NOT FORGET THIS. IT WILL NOT WORK OTHERWISE
         *     async fetch() {
         *          this.$api.run(getBusiness, BUSINESS_SLUG).then((business) => {
         *              init(this.$api, business.media_library_id)
         *          })
         *      },
         * ^^^^^^^^^^^^^^^^^^^^^
         * see for example JobEditPage.vue for reference
         */
        galleryInput: {
            type: Boolean,
            default: false,
        },
        /**
         * Limit the amount of media that can be uploaded
         * default -1 means no limit
         */
        mediaLimit: {
            type: Number,
            default: -1,
        },
        allowedTypes: {
            type: String,
            default: TYPE_ALL,
            validate: (value) =>
                [TYPE_ALL, TYPE_IMAGE, TYPE_VIDEO].includes(value),
        },
    },
    icons: {
        mdiPlus,
        mdiDelete,
        mdiCrop,
        mdiChevronLeft,
    },
    data: () => ({
        showCropModal: false,
        showGalleryModal: false,
        drag: false,
        gallery: null,
        dropping: false,
        dragOptions: {
            animation: 200,
            group: "images",
            disabled: false,
            ghostClass: "ghost",
            dragClass: "drag-ghost",
            chosenClass: "chosen-ghost",
        },
        croppingImage: null,
        acceptedFormats: MEDIA_TYPES,
        isChrome: false,
        error: null,
        galleryList: [],
        mediaTypes,
        selectedMediaType: "all",
    }),
    computed: {
        disableOuterFileInput() {
            // disabled if galleryInput is enabled because the field should only open the gallery not the file input in that case
            return !!this.croppingImage || this.galleryInput
        },
        gapSize() {
            return (this.mediaPerRow - 1) * 8
        },
        mediaStyles() {
            const width = `calc((100% - ${this.gapSize}px) / ${this.mediaPerRow}) !important`
            return {
                flexBasis: width,
            }
        },
        internalMediaListFlat() {
            return this.internalMediaList.map((el) => el.items).flat()
        },
        internalMediaList: {
            get() {
                return chunkArray(this.mediaList, this.mediaPerRow)
            },
            // handles both 1-D and 2-D arrays
            set(newVal) {
                const is2D = newVal[0] && Array.isArray(newVal[0].items)
                if (is2D) {
                    newVal = newVal.map((el) => el.items).flat()
                }
                if (this.mediaLimit > 0) {
                    newVal = newVal.slice(-this.mediaLimit)
                }
                this.galleryList = newVal
                this.$emit("update", newVal)
                this.$emit("update:title", newVal[0])
            },
        },
        galleryMediaList: {
            get() {
                return this.galleryList.length > 0
                    ? this.galleryList
                    : this.mediaList
            },
            set(newVal) {
                if (this.mediaLimit > 0) {
                    newVal = newVal.slice(-this.mediaLimit)
                }
                this.galleryList = newVal
            },
        },
        galleryOutOfSync() {
            const internalSet = new Set(this.internalMediaListFlat)
            const gallerySet = new Set(this.galleryMediaList)
            return (
                this.internalMediaListFlat.length !==
                    this.galleryMediaList.length ||
                this.internalMediaListFlat.some((el) => !gallerySet.has(el)) ||
                this.galleryMediaList.some((el) => !internalSet.has(el))
            )
        },
        isUploading() {
            return this.mediaList.some(
                (media) => media.request?.state === "pending"
            )
        },
    },
    watch: {
        isUploading(value) {
            this.$emit("uploading", value)
        },
        allowedTypes(value) {
            this.gallery?.destroy()
            this.gallery = newGallery(value, true)
        },
    },
    mounted() {
        this.isChrome = navigator.userAgent.match(/chrome|chromium|crios/i)
        if (this.galleryInput) {
            this.gallery = newGallery(this.allowedTypes, true)
            if (!this.gallery.initialized()) {
                console.error(
                    "MediaUploadList with gallery input used. But global gallery was not initialized. Make sure to initialize it somewhere. See MediaUploadList.vue -> props -> galleryInput for reference."
                )
            }
        }
    },
    beforeDestroy() {
        this.gallery.destroy()
    },
    methods: {
        onCloseGallery() {
            this.galleryMediaList = this.internalMediaListFlat
            this.showGalleryModal = false
        },
        onApplyGallery() {
            this.internalMediaList = this.galleryMediaList
            this.showGalleryModal = false
        },
        getImageSrc(croppingImage) {
            return (
                croppingImage.local ||
                this.$getImage({ image: croppingImage, preferedSize: 1 }).src
            )
        },
        onDeleteMedia(media) {
            media.request?.cancel?.()
            this.internalMediaList = this.internalMediaListFlat.filter(
                (curMedia) => curMedia !== media
            )
        },
        onDrag(e, value) {
            // dynamic binding doesn't work on clones
            const cloneVideos = e.clone.getElementsByTagName("video")
            if (cloneVideos && cloneVideos.length > 0) {
                cloneVideos[0].muted = true
                cloneVideos[0].autoplay = true
                cloneVideos[0].loop = true
                cloneVideos[0].playsinline = true
            }
            this.$emit("dragging", value)
            this.drag = value
        },
        onAdded(row) {
            let newMediaList = [...this.internalMediaList]
            newMediaList = newMediaList.filter(
                (curRow) => curRow.index !== row.index
            )
            newMediaList.push(row)
            newMediaList.sort((a, b) => a.index - b.index)
            this.internalMediaList = newMediaList
        },
        onUpdated(row) {
            let newMediaList = [...this.internalMediaList]
            newMediaList = newMediaList.filter(
                (curRow) => curRow.index !== row.index
            )
            newMediaList.push(row)
            newMediaList.sort((a, b) => a.index - b.index)
            this.internalMediaList = newMediaList
        },
        onClick() {
            this.galleryInput ? (this.showGalleryModal = true) : null
        },
        openCropImage(image) {
            let foundIndices = null
            this.internalMediaList.some((row) => {
                return row.items.some((curImage, j) => {
                    if (curImage === image) {
                        foundIndices = { i: row.index, j }
                        return true
                    }
                    return false
                })
            })
            if (!foundIndices) return
            const img = imageService.asClass(image)
            this.internalMediaList.find(
                (curRow) => curRow.index === foundIndices.i
            ).items[foundIndices.j] = img
            this.croppingImage = img
        },
        onFilesInput(files, fromGallery) {
            this.error = null
            let invalidFiles = []
            let validFiles = []
            if (!Array.isArray(files) && !(files instanceof FileList)) {
                files = [files]
            }
            for (const file of files) {
                if (!this.acceptedFormats.includes(file.type)) {
                    invalidFiles.push(file.name)
                } else {
                    validFiles.push(file)
                }
            }
            if (invalidFiles.length > 0) {
                if (invalidFiles.length === 1) {
                    this.error = `${invalidFiles[0]} hat ein ungültiges Format`
                } else {
                    this.error = `${invalidFiles.join(
                        ", "
                    )} haben ungültige Formate`
                }
            }
            this.uploadMedia(validFiles, fromGallery)
        },
        async onCrop(image) {
            this.croppingImage.update(image)
            this.croppingImage = null
            this.$emit("update", this.internalMediaListFlat)
        },
        cancelCrop() {
            this.croppingImage = null
        },
        /** External API  / base64 or file */
        async uploadMedia(files, fromGallery) {
            const newMedia = []
            if (!Array.isArray(files) && !(files instanceof FileList)) {
                files = [files]
            }
            if (files instanceof FileList) files = Array.from(files)
            for (const file of files) {
                let media = null
                let galleryTransaction = null
                if (file.type.startsWith("image")) {
                    media = imageService.asClass(file)
                    media.type = "image"
                    galleryTransaction = (m) => this.gallery?.addImage(m)
                }

                if (file.type.startsWith("video")) {
                    if (
                        this.allowedTypes === "all" ||
                        this.allowedTypes === "video"
                    ) {
                        media = videoService.asClass(file)
                        // fallback sizes; TODO: other mr will take care of this
                        media.width = 1000
                        media.height = 1000
                        media.type = "video"
                        galleryTransaction = (m) => this.gallery?.addVideo(m)
                    } else {
                        this.$emit(
                            "error",
                            "Ihr momentanes Abonnement erlaubt keine Videos."
                        )
                    }
                }
                if (!media) return
                media.create()
                media.uid = this.$getUID()
                newMedia.push(media)
                galleryTransaction(media)
            }
            if (fromGallery)
                this.galleryMediaList = [...this.galleryMediaList, ...newMedia]
            else {
                this.internalMediaList = [
                    ...this.internalMediaListFlat,
                    ...newMedia,
                ]
            }
        },
        onMediaTypeSwitch(newMediaTypeName) {
            this.selectedMediaType =
                Object.keys(mediaTypes).find(
                    (key) => mediaTypes[key] === newMediaTypeName
                ) ?? "all"
        },
    },
}
