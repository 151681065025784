
export default {
    name: "SelectSwitch",
    props: {
        /** Displayed items */
        items: {
            type: Array,
            required: true,
        },
        /** Sets the element disabled towards user input */
        disabled: {
            type: Boolean,
            default: false,
        },
        /** Selected item */
        value: {
            type: String,
            default: "",
        },
        /** Width of each item */
        itemWidth: {
            type: [Number, String],
            default: 100,
        },
        /** Overlapping between each item */
        overlap: {
            type: [Number, String],
            default: 18,
        },
        /** Maximum number of columns */
        maxColumns: {
            type: Number,
            default: 3,
        },
        light: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        itemsMatrix() {
            const matrix = []
            let row = []
            this.items.forEach((item, idx) => {
                if (idx % this.maxColumns === 0 && idx > 0) {
                    matrix.push(row)
                    row = []
                }
                row.push(item)
            })
            matrix.push(row)
            return matrix
        },
        wrapperStyles() {
            const length = Math.min(this.items.length, this.maxColumns)
            const totalOverlap = Math.max(
                0,
                (length - 1) * Number(this.overlap)
            )
            const width = length * Number(this.itemWidth) - totalOverlap
            return {
                width: `${width + 2}px`,
            }
        },
        itemListStyles() {
            return this.itemsMatrix.map((row) => {
                const rowStyles = []
                row.forEach((item, jndex) => {
                    const leftOffset =
                        jndex === 0 ? 0 : Number(this.overlap) * jndex
                    rowStyles.push({
                        left: `-${leftOffset}px`,
                        width: `${this.itemWidth}px`,
                    })
                })
                return rowStyles
            })
        },
    },
    methods: {
        isActive(item) {
            return item === this.value
        },
        getContainerStyles(itemsNum) {
            const right = (Number(this.overlap) * (itemsNum - 1)) / 2
            return {
                ...this.wrapperStyles,
                right: `-${right}px`,
            }
        },
    },
}
